(function () {
    'use strict';
}());

app.service('HomepageService', [
    'DOMAIN',
    function (DOMAIN) {
        var self = this;

        self.getCards = function () {
            var cards = [
                {
                    title: 'Solutions',
                    class: 'green',
                    items: [
                        { text: 'Exclusive Brands', wordpress: 'exclusive-brands'},
                        { text: 'Heat Stress', wordpress: 'heatstress'},
                        { text: 'Women in PPE', wordpress: 'womeninppe'},
                        { text: 'Industrial Coatings', wordpress: 'industrialcoatings' },
                        { text: 'Integrated Solutions', wordpress: 'integrated-solutions' },
                        { text: 'Metalworking', wordpress: 'metalworking' },
                        { text: 'MROP', wordpress: 'mrop' },
                        { text: 'Safety Services', wordpress: 'safetyservices' },
                        { text: 'Value Plus', wordpress: 'vpp' }
                    ],
                    button: {
                        text: 'All Services & Solutions',
                        wordpress: 'servicesandsolutions'
                    }
                },
                {
                    title: 'Customer Quick Reference Links',
                    class: 'orange',
                    items: [
                        { text: 'Quotes', route: 'account.quote', loginRequired : true},
                        { text: 'Retrieve Invoices', route: 'account.paid_invoices' , loginRequired : true},
                        { text: 'Tracking Numbers', route: 'account.revieworders' , loginRequired : true},
                        { text: 'Open Invoices', route: 'account.open_invoices', loginRequired : true},
                        { text: 'Open Orders', route: 'account.revieworders', loginRequired : true},
                        { text: 'Order History', route: 'account.orders', loginRequired : true },
                        { text: 'Coronavirus Update', wordpress: 'coronavirus' }
                    ],
                    button: {
                        text: 'Additional Resources',
                        route: 'account.profile',
                        loginRequired : true
                    }
                },
                {
                    title: 'Quick Reference Documents',
                    class: 'grey',
                    items: [
                        { text: 'ISO-9001 Certificate', route: 'resources.iso_certificate' },
                        { text: 'Resale Exemption Certificates', route: 'resources.resale_exemption_certificates' },
                        { text: 'W9 Downloads', route: 'resources.w9_downloads' },
                        { text: 'Credit Application', route: 'account_create.customer' },
                        { text: 'Supplier Routing Guide', route: 'resources.supplier_routing_guide' }
                    ],
                    button: {
                        text: 'Additional Resources',
                        hash: 'footer'
                    }
                },
                {
                    title: 'Flexible Procurement Solutions<small><sup>&reg;</sup></small>',
                    class: 'blue',
                    items: [
                        { text: 'Web Features', wordpress: 'web-features'  },
                        { text: 'eProcurement', wordpress: 'eprocurement'  },
                        { text: 'Dispensing', wordpress: 'dispensing-solutions' }
                    ],
                    button: {
                        text: 'More Information',
                        wordpress: 'flexibleprocurement'
                    }
                },
                {
                    title: 'Customer Login',
                    class: 'green',
                    template: 'login_card'
                },
                {
                    title: 'Contact Form',
                    class: 'orange',
                    template: 'contact_card'
                }
            ];

            if(DOMAIN === 'boringsmith') {
                cards.unshift({
                    title: 'Industries',
                    class: 'red',
                    items: [
                        {text: 'Manufactured Housing', wordpress: 'rv'},
                        {text: 'Recreational Vehicle', wordpress: ''},
                        {text: 'Trailer & Specialty Vehicles', wordpress: ''},
                        {text: 'Recreational Marine industries', wordpress: ''},
                    ],
                    button: {
                        text: 'All Industries',
                        wordpress: 'industries'
                    }
                });
            } else {
                cards.unshift({
                    title: 'Industries',
                    class: 'blue',
                    items: [
                        { text: 'Aerospace', wordpress: 'aerospace-solutions' },
                        { text: 'Automotive', wordpress: 'automotive' },
                        { text: 'Heavy Equipment',wordpress: 'heavyequipment' },
                        { text: 'Marine', wordpress: 'marine' },
                        { text: 'PetroChem', wordpress: 'petrochem' },
                        { text: 'Transportation', wordpress: 'transportation' }
                    ],
                    button: {
                        text: 'All Industries',
                        wordpress: 'industries'
                    }
                });
            }
            return cards;
        };

        self.getSlides = function() {
            if (DOMAIN === 'boringsmith') {
                return [
                    {
                        src: '/assets/images/home/slide-boring-smith-1.jpg',
                        title: 'Boring-Smith',
                        subTitle: 'Serving the Recreational Vehicle Business Since 1972'
                    }
                ];
            }

            return [
                {
                    src: '/assets/images/home/slide-manufacturing.jpeg',
                    title: 'Proven Solutions',
                    subTitle: 'Comprehensive Supply Chain Solutions for all of your Indirect Materials Challenges'
                },
                {
                    src: '/assets/images/home/slide-safety_solutions.jpeg',
                    title: 'Safety Solutions',
                    subTitle: 'Protecting Your People, Plant and Life-Saving Equipment'
                },
                {
                    src: '/assets/images/home/slide-metalworking.jpeg',
                    title: 'Metalworking Solutions',
                    subTitle: 'Solutionist dedicated to Optimizing your Metalworking Tools and Processes'
                }
            ];
        };

        self.getPartnerLogos = function() {
            if (DOMAIN === 'boringsmith')
            {
                return [
                    {
                        src: '3M_logo.png',
                        route: 'wordpress({path: "3m"})'
                    },
                    {
                        src: 'Freud_logo.png'
                    },
                    {
                        src: 'Irwin_Tools_logo.png'
                    },
                    {
                        src: 'PIP_logo.png',
                        route: 'wordpress({path: "pip"})'
                    },
                    {
                        src: 'Sioux_Mast_logo.png'
                    },
                    {
                        src: 'Stanley_Black_Decker_logo.png',
                        route: 'wordpress({path: "sbd"})'
                    },
                    {
                        src: 'Vega-logo.png'
                    }
                ]
            }
            return [
                {
                    src: '3M_logo.png',
                    route: 'wordpress({path: "3m"})'
                },
                {
                    src: 'Ansell_logo.png',
                    route: 'wordpress({path: "ansell"})'
                },
                {
                    src: 'Castrol_logo.png',
                    route: 'catalog_brand({slug: "castrol"})'
                },
                {
                    src: 'Channellock_logo.png',
                    route: 'wordpress({path: "Channellock"})'
                },
                {
                    src: 'CRC_logo.jpeg',
                    route: 'wordpress({path: "crc"})'
                },
                {
                    src: 'Dupont_Tyvek_logo.png',
                    route: 'wordpress({path: "dupont"})'
                },
                {
                    src: 'Energizer_logo.png',
                    route: 'catalog_brand({slug: "energizer"})'
                },
                {
                    src: 'Ergodyne_logo.png',
                    route: 'wordpress({path: "Ergodyne"})'
                },
                {
                    src: 'Gojo_logo.png',
                    route: 'wordpress({path: "gojo"})'
                },
                {
                    src: 'Greenfield_logo.png',
                    route: 'catalog_brand({slug: "greenfield"})'
                },
                {
                    src: 'Honeywell_logo.png',
                    route: 'wordpress({path: "honeywell"})'
                },
                {
                    src: 'Master-Fluid-Solutions_logo.jpg',
                    route: 'catalog_brand({slug: "master-fluid-solutions"})'
                },
                {
                    src: 'Masterlock_logo.png',
                    route: 'catalog_brand({slug: "master-lock-co"})'
                },
                {
                    src: 'MSA_logo.png',
                    route: 'catalog_brand({slug: "mine-safety-appliances-co"})'
                },
                {
                    src: 'OSG_shield_logo.png',
                    route: 'wordpress({path: "osg-co"})'
                },
                {
                    src: 'PIP_logo.png',
                    route: 'wordpress({path: "pip"})'
                },
                {
                    src: 'sandvik_coromant_logo.png',
                        route: 'wordpress({path: "sandvik"})'
                },
                {
                    src: 'Showa_logo.png',
                    route: 'wordpress({path: "showa"})'
                },
                {
                    src: 'WD-40_logo.png',
                    route: 'catalog_brand({slug: "wd-40-co"})'
                },
            ];
        };

        self.eatonCatalogs = function() {
            return [
                { catalog: 'Custom_Eaton_Batteries', title: 'Batteries Catalog' },
                { catalog: 'Custom_Eaton_Covid', title: 'Covid Catalog' },
                { catalog: 'Custom_Eaton_Field_Service_Technician', title: 'Field Service Tech Catalog' },
                { catalog: 'Custom_Eaton_General_Asst', title: 'General Catalog' },
                { catalog: 'Custom_Eaton_Gloves', title: 'Gloves Catalog' },
                { catalog: 'Custom_Eaton_Hearing', title: 'Hearing Catalog' },
                { catalog: 'Custom_Eaton_Janitorial', title: 'Janitorial Catalog' },
                { catalog: 'Custom_Eaton_Sorbents', title: 'Sorbents Catalog' },
                { catalog: 'Custom_Eaton_Tapes', title: 'Tapes Catalog' },
            ];
        };

        self.traneCatalogs = function() {
            return [
                { catalog: 'Custom_Trane_All_Kits', title: 'Kits Only' },
                { catalog: 'Custom_Trane_Arc_Flash_PPE_Kit_Back_Pack', title: 'Arc Flash PPE Kit - Back Pack' },
                { catalog: 'Custom_Trane_Arc_Flash_PPE_Kit_Kask', title: 'Arc Flash PPE Kit w/FR Kask' },
                { catalog: 'Custom_Trane_Arc_Flash_PPE_Kit_Orange_Bag', title: 'Arc Flash PPE Kit - Bag' },
                { catalog: 'Custom_Trane_Basic_Loto_Kit', title: 'LOTO Kit' },
                { catalog: 'Custom_Trane_Body_Production', title: 'Body Protection' },
                { catalog: 'Custom_Trane_Eye_Production', title: 'Eye Protection' },
                { catalog: 'Custom_Trane_Fall_Protection_Kits', title: 'Fall Protection Kits' },
                { catalog: 'Custom_Trane_Field_Associate_PPE', title: 'Field Associate PPE' },
                { catalog: 'Custom_Trane_FR_New_Tech_New_Hire_Bag', title: 'New Hire Kit - Field Tech FR' },
                { catalog: 'Custom_Trane_Hearing_Production', title: 'Hearing Protection' },
                { catalog: 'Custom_Trane_Kask_Helmet_And_Acc', title: 'Kask Helmet and Accessories' },
                { catalog: 'Custom_Trane_New_Tech_New_Hire_Bag', title: 'New Hire Kit - Field Tech' },
                { catalog: 'Custom_Trane_Other_Replacement_Items', title: 'Other Replacement Items' },
                { catalog: 'Custom_Trane_Size_8_Glove_Kit', title: 'Size 8 Glove Kit' },
                { catalog: 'Custom_Trane_Size_9_Glove_Kit', title: 'Size 9 Glove Kit' },
                { catalog: 'Custom_Trane_Size_10_Glove_Kit', title: 'Size 10 Glove Kit' },
                { catalog: 'Custom_Trane_Size_11_Glove_Kit', title: 'Size 11 Glove Kit' }
            ];
        };
    }
]);
